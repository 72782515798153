import { atom } from "jotai";
// Search
export const globalSearchPopupAtom = atom({
  show: false,
});
// Product Request
export const productRequestPopupAtom = atom({
  show: false,
  type: "",
  productId: "",
  companyId: "",
  alreadyRequested: false,
  newRequest: false,
  request: {},
});
// Job Application
export const jobApplicationPopupAtom = atom({
  show: false,
  lastCompany: "",
  experienceLevel: { value: "", label: "" },
  file: null,
  jobId: "",
});
// Delete Confirmation
export const deleteConfirmationPopupAtom = atom({
  show: false,
  collection: "",
  id: "",
  token: "",
  mutateId: "",
  mutateFunction: null,
});
// Login
export const loginPopupAtom = atom(false);

// Mobile Filter (Product Listing)
export const mobileFilterPopupAtom = atom({ show: false, loading: false });
